.tooltip {
  z-index: 1080;
  display: block;
  font-family: var(--bs-font-sans-serif);
  font-weight: 400;
  line-height: 1.5;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .tooltip-arrow {
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow {
  bottom: calc(-1 * 0.4rem);
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before {
  top: -3px;
  border-width: 0.4rem calc(0.8rem * 0.5) 0;
  border-top-color: black;
}

.tooltip-inner {
  max-width: 250px;
  padding: 0.25rem 0.5rem;
  color: white;
  text-align: center;
  background-color: #000000;
  border-radius: 5px;
  font-size: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
