@tailwind components;

@layer components {
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  .accordion-container {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar {
      background-color: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #626262;
    }
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .custom-scroll::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .custom-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}
