.swiper-button-prev,
.swiper-button-next {
  color: #1c2237ae;
  &:hover {
    color: #1c2237;
  }
}

.swiper-pagination-bullet {
  background-color: #1c2237;
}
