@tailwind components;

@layer components {
  .card-container:hover .remove-container {
    @apply block;
  }

  .card-container:hover .report-delete {
    @apply visible;
  }

  .tbody-column-content:hover .col-tooltip {
    @apply visible delay-1000;
  }

  .actions-col:hover .actions-col-tooltip {
    @apply visible delay-1000;
  }

  .table-report:hover .tooltip-report {
    @apply visible delay-500;
  }

  .table-remove-fav:hover .tooltip-remove-fav {
    @apply visible delay-500;
  }

  .table-fav:hover .tooltip-table-fav {
    @apply visible delay-500;
  }

  .table-del-product:hover .tooltip-del-product {
    @apply visible delay-500;
  }
}
